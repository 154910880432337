@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@layer utilities {
    .custom-scrollbar {
        scrollbar-width: thin; /* Firefox */
        scrollbar-color: rgb(147 51 234) rgb(31 41 55); /* Firefox */
    }

    /* For Chrome/Safari/Edge */
    .custom-scrollbar::-webkit-scrollbar {
        width: 8px;
        height: 8px;
    }

    .custom-scrollbar::-webkit-scrollbar-track {
        background: rgb(31 41 55); /* gray-800 */
        border-radius: 6px;
    }

    .custom-scrollbar::-webkit-scrollbar-thumb {
        background: rgb(147 51 234); /* purple-600 */
        border-radius: 6px;
        border: 2px solid rgb(31 41 55); /* Creates padding effect */
    }

    .custom-scrollbar::-webkit-scrollbar-thumb:hover {
        background: rgb(126 34 206); /* purple-700 */
    }

    /* For horizontal scrollbars */
    .custom-scrollbar::-webkit-scrollbar-track:horizontal {
        margin-inline: 6px;
    }

    .custom-scrollbar::-webkit-scrollbar-thumb:horizontal {
        border-radius: 6px;
    }

    /* Dark variant */
    .custom-scrollbar-dark {
        scrollbar-width: thin;
        scrollbar-color: rgb(75 85 99) rgb(17 24 39); /* gray-600 on gray-900 */
    }

    .custom-scrollbar-dark::-webkit-scrollbar {
        width: 8px;
        height: 8px;
    }

    .custom-scrollbar-dark::-webkit-scrollbar-track {
        background: rgb(17 24 39); /* gray-900 */
        border-radius: 6px;
    }

    .custom-scrollbar-dark::-webkit-scrollbar-thumb {
        background: rgb(75 85 99); /* gray-600 */
        border-radius: 6px;
        border: 2px solid rgb(17 24 39);
    }

    .custom-scrollbar-dark::-webkit-scrollbar-thumb:hover {
        background: rgb(107 114 128); /* gray-500 */
    }
}


body {
    font-family: "Roboto Slab", sans-serif;
    font-optical-sizing: auto;
    font-style: normal;
}